<template>
  <div class="contact">
    <sub-banner :title="banner.title" :subTitle="banner.subTitle" :bannerBg="banner.bannerBg"></sub-banner>
    <div class="content">
      <div class="contact-main">
        <article v-for="add of contentContact.subContacts" :key="add.contentItemId">
          <common-title :small="true" :title="add.location" align="left"></common-title>
          <div class="info-box">
            <p>Add：{{add.address}}</p>
            <p v-if="add.telphone">Tel：{{add.telphone}}</p>
          </div>
        </article>
        <ul>
          <li>商务合作：<a :href="'mailto:'+contentContact.defaultEmailCooperation">{{contentContact.defaultEmailCooperation}}</a></li>
          <li>简历投递：<a :href="'mailto:'+contentContact.defaultEmailResume">{{contentContact.defaultEmailResume}}</a></li>
        </ul>
        <div class="qr-code">
          <div style="margin-right: 20px">
            <img src="../../assets/images/yujieqrcode.jpeg" alt="">
            <div>商务合作</div>
          </div>
          <div>
            <img src="../../assets/images/qrcode.jpg" alt="">
            <div>公众号</div>
          </div>
        </div>
      </div>
      <div class="img-box">
        <!-- <img :src="contentContact.defaultMapImgUrl" class="map" alt="地图"> -->
        <img src="@/assets/images/account/map2.png" class="map" alt="地图">
      </div>
    </div>
  </div>
</template>

<script>
import pageMixin from '@/mixins/pageMixin'
import commonTitle from '@/components/commonTitle'
import subBanner from '@/components/subBanner'
import { mapActions, mapGetters } from 'vuex'
export default {
  mixins: [pageMixin],
  components: {
    subBanner,
    commonTitle
  },
  data () {
    return {
      banner: {
        title: '联系方式',
        subTitle: 'contact',
        bannerBg: require('@/assets/images/contact/banner.jpg')
      }
    }
  },
  computed: {
    ...mapGetters(['contentContact'])
  },
  methods: {
    ...mapActions(['fetchContentByKey'])
  },
  created () {
    this.fetchContentByKey('Contact')
  }
}
</script>

<style lang="less" scoped>
  .contact{
    .content{
      padding: 50px 40px;
      .contact-main{
        padding: 50px;
        background: #F5F7FF;
        color: @grey;
        article{
          margin-bottom: 80px;
        }
        .info-box{
          margin-top: 30px;
        }
        p{
          line-height: 40px;
          font-size: 26px;
        }
        ul{
          font-size: 26px;
          margin-bottom: 60px;
          li{
            max-width: 100%;
            margin-bottom: 20px;
            padding: 0 25px;
            line-height: 70px;
            background: #f5f7ff;
            box-shadow:0 0 20px 0 rgba(153,153,153,0.2);
          }
        }
        .qr-code {
          .flex;
          div {
            .flex(@direction: column);
            width: 50%;
            img {
              max-width: 100%;
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }
</style>
